
body {
  color: #797979;
  background: #ffffff;
  font-family: 'Montserrat', sans-serif;
}

body::-webkit-scrollbar {
  width: 12px;               /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: #fdeee2;        /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: #07212e;    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: 2px solid #fda65f;  /* creates padding around scroll thumb */
}

h1,
h2, 
h3, 
h4,
h5, 
h6 {
  color: #414141;
}

a {
  color: #313131;
  transition: .3s;
}

a:hover,
a:active,
a:focus {
  color: #EF233C;
  outline: none;
  text-decoration: none;
}

.btn:focus,
.form-control:focus {
  box-shadow: none;
}

.container-fluid {
  max-width: 1366px;
}

.navbar {
  position: relative;
  transition: .5s;
  z-index: 999;
}

.navbar.nav-sticky {
  position: fixed;
  top: 0;
  width: 100%;
  box-shadow: 0 2px 5px rgba(177, 177, 177, 0.3);
}

.navbar .navbar-brand {
  margin: 0;
  font-size: 45px;
  line-height: 0px;
  font-weight: 700;
  letter-spacing: 2px;
  transition: .5s;
  
}

.navbar .navbar-brand img {
  max-width: 100%;
  max-height: 40px;
}

.navbar .dropdown-menu {
  margin-top: 0;
  border: 0;
  border-radius: 0;
  background: #f8f9fa;
}

@media (min-width: 992px) {
  .navbar {
      position: absolute;
      width: 100%;
      padding: 30px 60px;
      background: transparent !important;
      border-bottom: 1px dashed rgba(256, 256, 256, .2);
      z-index: 9;
  }
  
  .navbar.nav-sticky {
      padding: 10px 60px;
      background: #051922 !important;
  }
  
  .navbar .navbar-brand {
      color: #ffffff;
  }
  
  .navbar.nav-sticky .navbar-brand {
      color: #ecf0f1;
  }

  .navbar-light .navbar-nav .nav-link,
  .navbar-light .navbar-nav .nav-link:focus {
      padding: 10px 10px 8px 10px;
      color: #ffffff;
      font-size: 15px;
      font-weight: 500;
  }
  
  .navbar-light.nav-sticky .navbar-nav .nav-link,
  .navbar-light.nav-sticky .navbar-nav .nav-link:focus {
      color: #f1f1f1;
  }

  .navbar-light .navbar-nav .nav-link:hover,
  .navbar-light .navbar-nav .nav-link.active {
      color: #F28123;
  }
  
  .navbar-light.nav-sticky .navbar-nav .nav-link:hover,
  .navbar-light.nav-sticky .navbar-nav .nav-link.active {
      color: #F28123;
  }
}

@media (max-width: 991.98px) {   
  .navbar {
      padding: 15px;
      background: #ffffff !important;
  }
  
  .navbar .navbar-brand {
      color: #F28123;
  }
  
  .navbar .navbar-nav {
      margin-top: 15px;
  }
  
  .navbar a.nav-link {
      padding: 5px;
  }
  
  .navbar .dropdown-menu {
      box-shadow: none;
  }
}











